import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ui-widget-error',
  templateUrl: './widget-error.component.html',
  styleUrls: ['./widget-error.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiWidgetErrorComponent {
  @Input() uiMessage: string;
}
