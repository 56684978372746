import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { UiGridListModule } from '../grid-list';
import { UiPageNotFoundModule } from '../page-not-found';
import { UiWidgetModule } from '../widget';
import { UiPageComponent } from './page.component';
import { UiPipesModule } from '../pipes';

@NgModule({
  declarations: [UiPageComponent],
  imports: [
    CommonModule,
    UiPageNotFoundModule,
    UiGridListModule,
    UiWidgetModule,
    UiPipesModule,
    NzModalModule,
    NzCheckboxModule,
    FormsModule,
    NzButtonModule,
    NzSpinModule,
  ],
  exports: [UiPageComponent],
})
export class UiPageModule {}
