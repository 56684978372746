export function generateId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function extractLastWord(value: string) {
  const words = value.split(' ');
  return words[words.length - 1] ?? '';
}

export function capitalize(value: string) {
  const words = value.split(' ');
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
