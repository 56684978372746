export function objSame(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function objCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function objEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export function objPick<T>(obj: T, keys: string[]) {
  return keys.reduce((partial, key) => {
    partial[key] = obj[key];
    return partial;
  }, {});
}

export function arrayToObj<T, K extends keyof T>(array: T[], keyField: K): { [key: string]: T } {
  const obj = {};

  array.forEach(item => {
    obj[item[keyField] as any] = item;
  });

  return obj;
}

export function objReplaceNaNWith<T>(obj: T, replacement: any): T {
  return JSON.parse(JSON.stringify(obj).replace(/"NaN"/g, replacement));
}

export function objReplaceStringInfinity<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj), (key, value) => {
    if (value === 'Infinity') return Infinity;
    if (value === '-Infinity') return -Infinity;
    return value;
  });
}
