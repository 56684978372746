import { inject, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import type { ReportsLiteModalComponent } from '../../modules/reports-lite/reports-lite-modal/reports-lite-modal.component';
import type { ReportsModalComponent } from '../../modules/reports/reports-modal/reports-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ReportsModalLoaderService {
  private _injector = inject(Injector);

  async renderModalComponent(vcr: ViewContainerRef) {
    const component = await this.loadModalComponent();
    return vcr.createComponent(component, { injector: this._injector });
  }

  loadModalComponent(): Promise<Type<ReportsLiteModalComponent | ReportsModalComponent>> {
    return environment.useLiteReportsModal
      ? import('../../modules/reports-lite/reports-lite-modal/reports-lite-modal.component').then(
          c => c.ReportsLiteModalComponent,
        )
      : import('../../modules/reports/reports-modal/reports-modal.component').then(
          c => c.ReportsModalComponent,
        );
  }
}
