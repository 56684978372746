import { DragDrop, DragRef } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'ui-grid-list-highlight',
  template: '',
  styleUrls: ['./grid-list-highlight.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.ui-grid-list-highlight]': 'true' },
})
export class UiGridListHighlightComponent implements OnDestroy {
  dragRef: DragRef;
  private _el = this._elRef.nativeElement;

  constructor(
    private _elRef: ElementRef<HTMLElement>,
    private _dragDrop: DragDrop,
    private _renderer: Renderer2,
    private _cdr: ChangeDetectorRef,
  ) {
    this.dragRef = this._dragDrop.createDrag(this._elRef);
  }

  ngOnDestroy() {
    this.dragRef?.dispose();
  }

  toggleVisibility(visible: boolean) {
    this._renderer.setStyle(this._el, 'display', visible ? 'block' : 'none');
  }

  updatePosition(left: number, top: number, width: number, height: number) {
    this._renderer.setStyle(this._el, 'width', width + 'px');
    this._renderer.setStyle(this._el, 'height', height + 'px');
    this._renderer.setStyle(this._el, 'transform', `translate3d(${left}px, ${top}px, 0px)`);
  }
}
