import { IStatusResponse } from '@base-frontend/core';

export interface IReport {
  ReportName: string;
  TemplateId: string;
  TransactionId: string;
  Completed: boolean;
  Progress: number;
  DateCreated: string;
  WellName: string;
  RunNumber: string;
  State: EReportState;
  Comment: string;
}

export interface IReportStatusResponse extends IStatusResponse {
  Status: {
    State: EReportState;
    Progress: number;
    TemplateId: string;
  };
  WellName: string;
  RunNumber: string;
  Comment: string;
}

export enum EReportState {
  Unknown,
  Creating,
  Created,
  CreationError,
  Importing,
  Imported,
  ImportError,
  Exporting,
  Exported,
  ExportError,
  Deleting,
  Deleted,
}
