import { ComponentFactoryResolver, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { NzGlobalTemplatesComponent, nzConfigFactory } from './nz-config';
import { nzIcons } from './nz-icons';

@NgModule({
  imports: [NzIconModule],
  declarations: [NzGlobalTemplatesComponent],
})
export class UiModule {
  static forRoot(): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [
        { provide: NZ_I18N, useValue: en_US },
        { provide: NZ_ICONS, useValue: nzIcons },
        {
          provide: NZ_CONFIG,
          useFactory: nzConfigFactory,
          deps: [Injector, ComponentFactoryResolver],
        },
      ],
    };
  }
}
