import { Component, inject, OnInit, ViewContainerRef } from '@angular/core';
import { IframeUrlPersistenceService } from '@base-frontend/core';
import { ReportsModalLoaderService } from './services/reports';

@Component({
  selector: 'pr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  // Needed for persisting url when this app is loaded via an iframe
  private _iframeUrlPersistenceService = inject(IframeUrlPersistenceService);
  private _vcr = inject(ViewContainerRef);
  private _reportsModalLoaderService = inject(ReportsModalLoaderService);

  async ngOnInit() {
    await this._reportsModalLoaderService.renderModalComponent(this._vcr);
  }
}
