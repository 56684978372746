import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputBoolean } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'ui-widget-section',
  template: '<ng-content></ng-content>',
  styleUrls: ['./widget-section.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-widget-section',
    '[class.is-interactive]': 'uiInteractive',
    '[style.height]': 'uiFullHeight ? "100%" : ""',
  },
})
export class UiWidgetSectionComponent {
  @Input('uiInteractive') uiInteractive: boolean;
  @Input('uiFullHeight') @InputBoolean() uiFullHeight: boolean;
}
