import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { EStatus } from '../../common';

@Component({
  selector: 'ui-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-widget-header',
  },
})
export class UiWidgetHeaderComponent {
  @Input() uiTitle: string;
  @Input() uiStatus: EStatus;
  @Input() @InputBoolean() uiDisplayActionsOnHover: boolean;

  get status() {
    return this.uiStatus || EStatus.Unknown;
  }
}
