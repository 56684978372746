import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiFitViewportDirective } from './fit-viewport.directive';
import { UiIntersectObserverDirective } from './intersect-observer.directive';
import { UiMaximizeDirective } from './maximize.directive';
import { UiRenderDelayDirective } from './render-delay.directive';
import { UiRevertToWhenEmptyDirective } from './revert-to-when-empty.directive';
import { UiTruncateTextDirective } from './truncate-text.directive';

const directives = [
  UiTruncateTextDirective,
  UiIntersectObserverDirective,
  UiFitViewportDirective,
  UiRenderDelayDirective,
  UiRevertToWhenEmptyDirective,
  UiMaximizeDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
  imports: [CommonModule],
})
export class UiDirectivesModule {}
