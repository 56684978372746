export function isNil(value: any): value is null | undefined {
  return typeof value === 'undefined' || value === null;
}

export function isFunction(value: any): value is Function {
  return typeof value === 'function';
}

export function isString(value: any): value is String {
  return typeof value === 'string';
}

export function isDateString(value: any): value is String {
  if (typeof value !== 'string') return false;
  if (!isNaN(Number(value))) return false;

  const date = new Date(value);

  return !isNaN(date.getTime());
}
