import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UiIntersectObserverService {
  private _intersect$ = new Subject<IntersectionObserverEntry[]>();
  private _observer = new IntersectionObserver(entries => this._intersect$.next(entries));

  observe(target: Element) {
    this._observer.observe(target);
    return this._intersect$.pipe(
      map(entries => {
        const length = entries.length;
        for (let i = length - 1; i >= 0; i--) {
          if (entries[i].target === target) {
            return entries[i];
          }
        }
        return null;
      }),
      filter(entry => !!entry),
    );
  }

  unobserve(target: Element) {
    this._observer.unobserve(target);
  }
}
