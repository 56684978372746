import { IconDefinition } from '@ant-design/icons-angular';

const closeIcon =
  '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/></svg>';
const errorIcon =
  '<svg viewBox="0 0 14 14"><path fill="currentColor" d="M9.9,0H4.1L0,4.1v5.8L4.1,14h5.8L14,9.9V4.1L9.9,0z M11,8H3V6 h8V8z"/></svg>';
const warningIcon =
  '<svg viewBox="0 0 14 14"><path fill="currentColor" d="M13.88,12.51L7.9,1.53c-0.1-0.18-0.24-0.32-0.42-0.41C6.99,0.86,6.37,1.04,6.1,1.53L0.12,12.51 C0.04,12.66,0,12.82,0,12.99C0,13.55,0.46,14,1.02,14h11.95c0.17,0,0.34-0.04,0.49-0.12c0.01,0,0.01-0.01,0.02-0.01 C13.96,13.61,14.14,13,13.88,12.51z M7,12c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C8,11.55,7.55,12,7,12z M8,8 c0,0.55-0.45,1-1,1S6,8.55,6,8V5c0-0.55,0.45-1,1-1s1,0.45,1,1V8z"/></svg>';
const successIcon =
  '<svg viewBox="0 0 14 14"><path fill="currentColor" d="M5.14,11.5c-0.36,0-0.71-0.16-0.94-0.43L1.3,7.74C0.84,7.22,0.9,6.44,1.42,5.98s1.31-0.4,1.76,0.12 l1.96,2.25l5.16-5.92c0.45-0.52,1.24-0.58,1.76-0.12c0.52,0.45,0.57,1.24,0.12,1.76l-6.1,7C5.84,11.34,5.5,11.5,5.14,11.5z"/></svg>';
const infoIcon =
  '<svg viewBox="0 0 14 14"><path fill="currentColor" d="M7,0C3.1,0,0,3.1,0,7s3.1,7,7,7s7-3.1,7-7S10.9,0,7,0z M8,10.5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3 c0-0.6,0.4-1,1-1s1,0.4,1,1V10.5z M7,5.5C6.2,5.5,5.5,4.8,5.5,4S6.2,2.5,7,2.5S8.5,3.2,8.5,4S7.8,5.5,7,5.5z"/></svg>';
const healthIcon =
  '<svg viewBox="0 0 24 24"><title>hospital-box</title><path d="M18,14H14V18H10V14H6V10H10V6H14V10H18M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" /></svg>';

export const nzIcons = <IconDefinition[]>[
  { name: 'close', theme: 'fill', icon: closeIcon },
  { name: 'close', theme: 'outline', icon: closeIcon },
  { name: 'close-circle', theme: 'fill', icon: errorIcon },
  { name: 'close-circle', theme: 'outline', icon: errorIcon },
  { name: 'exclamation-circle', theme: 'fill', icon: warningIcon },
  { name: 'exclamation-circle', theme: 'outline', icon: warningIcon },
  { name: 'check-circle', theme: 'fill', icon: successIcon },
  { name: 'check-circle', theme: 'outline', icon: successIcon },
  { name: 'info-circle', theme: 'fill', icon: infoIcon },
  // { name: 'info-circle', theme: 'outline', icon: infoIcon }
  { name: 'health-info', theme: 'fill', icon: healthIcon },
  { name: 'health-info', theme: 'outline', icon: healthIcon },
];
