import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UiGridListItemComponent } from './grid-list-item.component';
import { IUiGridListDrag, IUiGridListResize } from './symbols';

@Injectable()
export class GridListService {
  dragStart$ = new Subject<IUiGridListDrag>();
  dragMove$ = new Subject<IUiGridListDrag>();
  dragEnd$ = new Subject<IUiGridListDrag>();
  resizeStart$ = new Subject<UiGridListItemComponent>();
  resizeMove$ = new Subject<IUiGridListResize>();
  resizeEnd$ = new Subject<UiGridListItemComponent>();
  itemUpdate$ = new Subject<UiGridListItemComponent>();
  itemAdd$ = new Subject<UiGridListItemComponent>();
  itemRemove$ = new Subject<UiGridListItemComponent>();
}
