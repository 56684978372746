import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiPageNotFoundComponent } from './page-not-found.component';

@NgModule({
  declarations: [UiPageNotFoundComponent],
  imports: [CommonModule],
  exports: [UiPageNotFoundComponent],
})
export class UiPageNotFoundModule {}
