import {
  ComponentRef,
  createNgModuleRef,
  Directive,
  Inject,
  Injector,
  Input,
  NgModuleRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUiWidgetRegistry, UI_WIDGET_REGISTRY } from './symbols';

@Directive({
  selector: '[uiLoadWidget]',
  exportAs: 'uiLoadWidget',
})
export class UiLoadWidgetDirective implements OnChanges, OnDestroy {
  @Input('uiLoadWidget') widgetName: string;
  @Input('uiInputs') inputs: { [property: string]: any } = {};

  failedToLoad$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);

  private _moduleRef: NgModuleRef<any>;
  private _componentRef: ComponentRef<any>;

  constructor(
    private _vcr: ViewContainerRef,
    private _injector: Injector,
    @Inject(UI_WIDGET_REGISTRY) private _registry: IUiWidgetRegistry,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.widgetName) {
      this.loadWidget();
    }

    if (changes.inputs) {
      this.updateInputs();
    }
  }

  ngOnDestroy() {
    this._moduleRef?.destroy();
    this._vcr.clear();
  }

  private loadWidget() {
    this._vcr.clear();

    if (!this.widgetName) return;

    this.loading$.next(true);

    const loadModule = this._registry[this.widgetName];
    if (!loadModule) {
      return this.handleLoadError(`Widget ${this.widgetName} is not registered...`);
    }

    loadModule()
      .then(module => {
        this._moduleRef = createNgModuleRef(module, this._injector);
        this._componentRef = this._vcr.createComponent(module.entry, {
          ngModuleRef: this._moduleRef,
        });
        this.updateInputs();

        this.failedToLoad$.next(false);
        this.loading$.next(false);
      })
      .catch(e => this.handleLoadError(e));
  }

  private updateInputs() {
    if (!this._componentRef) return;

    if (!this._componentRef.instance.inputs$) {
      this._componentRef.instance.inputs$ = new BehaviorSubject(this.inputs);
    } else {
      this._componentRef.instance.inputs$.next(this.inputs);
    }
  }

  private handleLoadError(error: string) {
    this.failedToLoad$.next(true);
    this.loading$.next(false);
    console.error(error);
  }
}
