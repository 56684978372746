export function msToHHMMSS(duration: number) {
  let hours: any = Math.floor(duration / (3600 * 1000));
  let minutes: any = Math.floor((duration / (1000 * 60)) % 60);
  let seconds: any = Math.ceil((duration / 1000) % 60);

  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;
  seconds = (seconds < 10 ? '0' : '') + seconds;

  return hours + ':' + minutes + ':' + seconds;
}

export function msToMMSS(duration: number) {
  let minutes: any = Math.floor((duration / (1000 * 60)) % 60);
  let seconds: any = Math.floor((duration / 1000) % 60);

  minutes = (minutes < 10 ? '0' : '') + minutes;
  seconds = (seconds < 10 ? '0' : '') + seconds;

  return minutes + ':' + seconds;
}

export function msToDuration(
  ms: number,
  minUnit: 'd' | 'h' | 'm' | 's' = 's',
  maxUnit: 'd' | 'h' | 'm' | 's' = 'd',
  displayWhenNull = '',
  displayWhenZero = '',
) {
  const units = ['d', 'h', 'm', 's'];
  const isNegative = ms < 0;

  if (ms === null) {
    return displayWhenNull;
  }

  const parts = msToParts(ms, maxUnit);
  const startIndex = units.indexOf(maxUnit);
  const endIndex = units.indexOf(minUnit);
  const nonEmptyParts = [];

  for (let i = startIndex; i <= endIndex; i++) {
    const part = parts[units[i]];
    if (part !== 0) {
      nonEmptyParts.push(part + units[i]);
    }
  }

  if (nonEmptyParts.length === 0) {
    return displayWhenZero;
  }

  return isNegative ? '-' + nonEmptyParts.join(' ') : nonEmptyParts.join(' ');
}

export function msToParts(ms: number, maxUnit: 'd' | 'h' | 'm' | 's' = 'd') {
  const units = ['d', 'h', 'm', 's'];
  const unitLength = units.length;
  const msInUnit = {
    d: 1000 * 60 * 60 * 24, // milliseconds in a day
    h: 1000 * 60 * 60, // milliseconds in an hour
    m: 1000 * 60, // milliseconds in a minute
    s: 1000, // milliseconds in a second
  };

  const parts = { d: 0, h: 0, m: 0, s: 0 };
  const startIndex = units.indexOf(maxUnit);
  let tempValue = Math.abs(ms);

  for (let i = startIndex; i < unitLength; i++) {
    const unit = units[i];
    const unitValue = Math.floor(tempValue / msInUnit[unit]);

    if (unitValue < 1) continue;

    parts[unit] = unitValue;
    tempValue -= unitValue * msInUnit[unit];
  }

  return parts;
}

export function toISOStringWithoutTimezone(dateTime) {
  const date = new Date(dateTime);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.toISOString().replace(/Z.*/, '');
}
