import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { fromResize, WINDOW } from '@base-frontend/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, of, Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { GridList } from './grid-list';
import { UiGridListHighlightComponent } from './grid-list-highlight.component';
import { UiGridListItemComponent } from './grid-list-item.component';
import { GridListService } from './grid-list.service';
import { IUiGridListDrag, IUiGridListResize } from './symbols';

@UntilDestroy()
@Component({
  selector: 'ui-grid-list',
  template: `
    <ui-grid-list-highlight></ui-grid-list-highlight>
    <ng-content></ng-content>
  `,
  styleUrls: ['./grid-list.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridListService],
  host: {
    '[class.ui-grid-list]': 'true',
    '[class.ui-grid-list-dragging]': 'dragging',
    '[class.ui-grid-list-resizing]': 'resizing',
  },
})
export class UiGridListComponent implements OnChanges, OnInit, OnDestroy {
  @Input() uiLanes: number;
  @Input() uiGap = 3;
  @ViewChild(UiGridListHighlightComponent, { static: true })
  highlight: UiGridListHighlightComponent;
  dragging = false;
  resizing = false;

  private _grid: GridList;
  private _itemComps: UiGridListItemComponent[] = [];
  private _cellHeight: number;
  private _cellWidth: number;
  private _previousDragPosition: number[];
  private _previousResizeSize: { w: number; h: number };
  private _updateGrid$ = new Subject();

  get cellRatio() {
    return this._window.innerWidth / this._window.innerHeight;
  }

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _renderer: Renderer2,
    private _elRef: ElementRef<HTMLElement>,
    private _gridListService: GridListService,
    private _ngZone: NgZone,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscribeToItemChanges();
    this.subscribeToDragEvents();
    this.subscribeToResizeEvents();

    this._updateGrid$
      .pipe(
        debounceTime(50),
        tap(() => this.calculateCellSize()),
        switchMap(() => this.autoSizeItems$()),
        untilDestroyed(this),
      )
      .subscribe(() => this.initGrid());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._grid) {
      return;
    }

    this.refresh();
  }

  ngOnDestroy() {}

  refresh() {
    this._updateGrid$.next();
  }

  private subscribeToResizeEvents() {
    fromResize(this._elRef.nativeElement)
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(() => {
        if (!this.dragging && !this.resizing) {
          this.refresh();
        }
      });

    this._gridListService.resizeStart$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onResizeStart());
    this._gridListService.resizeEnd$
      .pipe(untilDestroyed(this))
      .subscribe(event => this.onResizeEnd(event));

    this._ngZone.runOutsideAngular(() => {
      this._gridListService.resizeMove$
        .pipe(untilDestroyed(this))
        .subscribe(event => this.onResizeMove(event));
    });
  }

  private subscribeToDragEvents() {
    this._gridListService.dragStart$.pipe(untilDestroyed(this)).subscribe(() => this.onDragStart());
    this._gridListService.dragEnd$
      .pipe(untilDestroyed(this))
      .subscribe(event => this.onDragEnd(event));

    this._ngZone.runOutsideAngular(() => {
      this._gridListService.dragMove$
        .pipe(untilDestroyed(this))
        .subscribe(event => this.onDragMove(event));
    });
  }

  private subscribeToItemChanges() {
    this._gridListService.itemAdd$.pipe(untilDestroyed(this)).subscribe(itemComp => {
      this._itemComps.push(itemComp);
      this.refresh();
    });

    this._gridListService.itemRemove$.pipe(untilDestroyed(this)).subscribe(itemComp => {
      const index = this._itemComps.indexOf(itemComp);

      if (index !== -1) {
        this._itemComps.splice(index, 1);
        this.refresh();
      }
    });

    this._gridListService.itemUpdate$
      .pipe(untilDestroyed(this))
      .subscribe(() => this._updateGrid$.next());
  }

  private initGrid() {
    const items = this._itemComps.map(item => item.uiOptions).sort((a, b) => a.order - b.order);
    this._grid = new GridList([...items], {
      lanes: this.uiLanes,
      floatUp: true,
      defaultItemWidth: Math.max(1, Math.round(this.uiLanes / 5)),
      defaultItemHeight: 1,
    });
    this.updateItemPositions();
  }

  private onDragStart() {
    this.dragging = true;
    this._cdr.markForCheck();
  }

  private onResizeStart() {
    this.resizing = true;
    this._renderer.setStyle(this._window.document.body, 'cursor', 'nwse-resize');
    this._cdr.markForCheck();
  }

  private onDragMove({ component }: IUiGridListDrag) {
    const position = this.snapItemPositionToGrid(component);

    if (this.dragPositionChanged(position)) {
      this._previousDragPosition = position;
      component.uiOptions.x = position[0];
      component.uiOptions.y = position[1];
      this.highlightPositionForItem(component);
    }
  }

  private onDragEnd({ component }: IUiGridListDrag) {
    component.uiOptions.autoPosition = false;
    this._grid.moveItemToPosition(component.uiOptions, this._previousDragPosition);
    this.dragging = false;
    this._previousDragPosition = null;
    this.onDragResizeEnd();
  }

  private onResizeMove({ width, height, component }: IUiGridListResize) {
    const size = this.snapItemDimensionsToGrid(width, height);

    if (this.resizeSizeChanged(size)) {
      component.uiOptions.w = size.w;
      component.uiOptions.h = size.h;
      this._previousResizeSize = size;
      this.highlightPositionForItem(component);
    }
  }

  private onResizeEnd(itemComp: UiGridListItemComponent) {
    itemComp.uiOptions.autoSize = false;
    if (this._previousResizeSize) {
      this._grid.resizeItem(itemComp.uiOptions, this._previousResizeSize);
      this._previousResizeSize = null;
    }
    this.resizing = false;
    this._renderer.removeStyle(this._window.document.body, 'cursor');
    this.onDragResizeEnd();
  }

  private onDragResizeEnd() {
    this.updateItemPositions();
    this.highlight.toggleVisibility(false);
    this._cdr.markForCheck();
  }

  private autoSizeItems$() {
    const autoSizeItemComps = this._itemComps.filter(item => item.uiOptions.autoSize);

    return !autoSizeItemComps.length
      ? of(true)
      : forkJoin(
          autoSizeItemComps.map(item =>
            item.calcIntrinsicHeight$(this._cellWidth).pipe(
              tap(height => {
                // + 5 to ensure scrollbar is hidden
                item.uiOptions.h = Math.ceil((height + 3) / this._cellHeight);
              }),
            ),
          ),
        );
  }

  private highlightPositionForItem(itemComp: UiGridListItemComponent) {
    const { left, top, width, height } = this.calculateItemPosition(itemComp);

    this.highlight.updatePosition(left, top, width, height);
    this.highlight.toggleVisibility(true);
  }

  private calculateCellSize() {
    const el = this._elRef.nativeElement;
    this._cellWidth = (el.clientWidth + this.uiGap) / this.uiLanes;
    this._cellHeight = Math.floor(this._cellWidth / this.cellRatio);
  }

  private updateItemPositions() {
    this._itemComps.forEach(itemComp => {
      if (!itemComp.dragRef.isDragging()) {
        const { left, top, width, height } = this.calculateItemPosition(itemComp);
        itemComp.updatePosition(left, top, width, height);
      }
    });
  }

  private calculateItemPosition(itemComp: UiGridListItemComponent) {
    const left = Math.round(itemComp.uiOptions.x * this._cellWidth);
    const top = Math.round(itemComp.uiOptions.y * this._cellHeight);
    const width = itemComp.uiOptions.w * this._cellWidth - this.uiGap;
    const height = itemComp.uiOptions.h * this._cellHeight - this.uiGap;
    return { left, top, width, height };
  }

  private snapItemPositionToGrid(itemComp: UiGridListItemComponent) {
    const position = itemComp.getPosition();
    let col = Math.round(position.left / this._cellWidth);
    let row = Math.round(position.top / this._cellHeight);

    // Keep item position within the grid and don't let the item create more
    // than one extra column
    col = Math.max(col, 0);
    row = Math.max(row, 0);

    col = Math.min(col, this.uiLanes - itemComp.uiOptions.w);

    return [col, row];
  }

  private snapItemDimensionsToGrid(width: number, height: number) {
    let w = Math.round(width / this._cellWidth);
    let h = Math.round(height / this._cellHeight);

    w = Math.max(w, 1);
    h = Math.max(h, 1);

    w = Math.min(w, this.uiLanes);

    return { h, w };
  }

  private dragPositionChanged(newPosition: number[]) {
    if (!this._previousDragPosition) {
      return true;
    }
    return (
      newPosition[0] !== this._previousDragPosition[0] ||
      newPosition[1] !== this._previousDragPosition[1]
    );
  }

  private resizeSizeChanged(newSize: { w: number; h: number }) {
    if (!this._previousResizeSize) {
      return true;
    }
    return newSize.w !== this._previousResizeSize.w || newSize.h !== this._previousResizeSize.h;
  }
}
