import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SigninCallbackRedirect } from './signin-callback-redirect';

export function oidcInitializerFactory(authService: AuthService, router: Router, _window: Window) {
  const isSigninCallback = _window.location.pathname === authService.signinCallbackPath;

  // Add signin callback route
  router.config.unshift({
    // Remove leading slash for route path
    path: authService.signinCallbackPath.substring(1),
    canActivate: [SigninCallbackRedirect],
  });

  return async () => {
    if (isSigninCallback) return true;

    const user = await authService.getUser();
    if (!user || user.expired) {
      await authService.login();
      return false;
    }

    return true;
  };
}
