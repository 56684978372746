export function clamp(value: number, min: number, max: number) {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
}

export function isInfinity(value: number) {
  return Math.abs(value) === Infinity;
}

export function minDistBetweenAngles(a: number, b: number) {
  if (typeof a !== 'number' || typeof b !== 'number') {
    return null;
  }

  const clockwiseDist = a > b ? 360 - a + b : b - a;
  const counterClockwiseDist = a > b ? a - b : 360 - b + a;
  return Math.min(clockwiseDist, counterClockwiseDist);
}

export function sum(values: number[]) {
  return values.reduce((total, value) => total + value, 0);
}

export function digitsBeforeDecimal(value: number) {
  const strValue = value.toString();
  return strValue.split('.')[0]?.length || strValue.length;
}

export function digitsAfterDecimal(value: number) {
  return value.toString().split('.')[1]?.length || 0;
}

export function precisionFormatter(precision: number, showTrailingZeros = true) {
  return (value: number | string | null): string => {
    if (value === null || value === undefined) return null;

    if (typeof value === 'number') {
      return showTrailingZeros ? value.toFixed(precision) : (+value.toFixed(precision)).toString();
    }

    if (typeof value === 'string') {
      const parsedValue = parseFloat(value);

      if (isNaN(parsedValue)) return value;
      return showTrailingZeros
        ? parsedValue.toFixed(precision)
        : (+parsedValue.toFixed(precision)).toString();
    }

    return value;
  };
}
