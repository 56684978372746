import { Injectable } from '@angular/core';
import { objCopy } from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  logError(message: any) {
    // Here we can also log errors to a server (Slack can be an option to)
    console.error(message instanceof Error ? message : objCopy(message));
  }

  logWarning(message: any) {
    console.warn(objCopy(message));
  }

  logSuccess(message: any) {
    console.log(objCopy(message));
  }

  logInfo(message: any) {
    console.log(objCopy(message));
  }
}
