import { Pipe, PipeTransform } from '@angular/core';
import { msToParts } from '@base-frontend/core';

@Pipe({
  name: 'uiMsToParts',
})
export class UiMsToParts implements PipeTransform {
  transform(milliseconds: number, maxUnit: 'd' | 'h' | 'm' | 's' = 'd') {
    return msToParts(milliseconds, maxUnit);
  }
}
