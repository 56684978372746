import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageSubject } from '../../rxjs';

export const THEMES = ['Dark', 'Light'] as const;
export type TTheme = typeof THEMES[number];

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _theme$ = new StorageSubject<TTheme>('Theme', THEMES[0]);
  theme$ = this._theme$.pipe(distinctUntilChanged());

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  loadTheme(theme: TTheme = this._theme$.value) {
    let classList = Array.from(this._document.body.classList);

    // Remove previous theme class
    classList = classList.filter(c => !c.startsWith('ui-theme-'));

    // Append new theme class
    classList.push(`ui-theme-${theme.toLowerCase()}`);

    this._document.body.className = classList.join(' ');

    this._theme$.next(theme);
  }
}
