import { NgModule } from '@angular/core';
import { UiCeilPipe } from './ceil.pipe';
import { UiFloorPipe } from './floor.pipe';
import { UiMsToDurationPipe } from './ms-to-duration.pipe';
import { UiMsToParts } from './ms-to-parts.pipe';
import { UiSecureImagePipe } from './secure-image.pipe';
import { UiSortPipe } from './sort.pipe';

const pipes = [
  UiSortPipe,
  UiMsToDurationPipe,
  UiMsToParts,
  UiCeilPipe,
  UiFloorPipe,
  UiSecureImagePipe,
];

@NgModule({
  declarations: [pipes],
  exports: pipes,
  imports: [],
})
export class UiPipesModule {}
