import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IPage, UiPageModule } from '@base-frontend/ui';
import { ReportsService } from '../../services/reports';
import { IBlankWidget } from '../../widgets/blank/symbols';
import { INavbarWidget } from '../../widgets/navbar/symbols';

@Component({
  selector: 'pr-blank-page',
  templateUrl: './blank-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiPageModule],
})
export class BlankPageComponent {
  page = <IPage>{
    PageId: null,
    Widgets: [
      <INavbarWidget>{
        Id: 'navbar',
        Type: 'navbar',
        Layout: { Type: 'top' },
        HideHeader: true,
        Breadcrumbs: [],
      },
      <IBlankWidget>{
        Id: '0',
        Type: 'blank',
        Layout: { Type: 'top' },
        Height: '150px',
      },
      ...Array(8)
        .fill(1)
        .map((v, i) => {
          return <IBlankWidget>{
            Id: (i + 1).toString(),
            Type: 'blank',
            Layout: { Type: 'grid', W: 6, H: 8 },
          };
        }),
    ],
  };

  constructor(private _reportsService: ReportsService) {
    this._reportsService.openModal();
  }
}
