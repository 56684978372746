import { HttpClient } from '@angular/common/http';
import { inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const UI_SECURE_IMAGE_PIPE_ENABLED = new InjectionToken('UI_SECURE_IMAGE_PIPE_ENABLED', {
  providedIn: 'root',
  factory: () => false,
});

@Pipe({
  name: 'uiSecureImage',
})
export class UiSecureImagePipe implements PipeTransform {
  private _isEnabled = inject(UI_SECURE_IMAGE_PIPE_ENABLED);
  private _domSanitizer = inject(DomSanitizer);
  private _httpClient = inject(HttpClient);

  transform(src: string) {
    return this._isEnabled ? this.loadImage(src) : of(src);
  }

  private loadImage(url: string): Observable<SafeUrl> {
    return this._httpClient.get(url, { responseType: 'blob' }).pipe(
      map(event => {
        const url = URL.createObjectURL(event);

        return this._domSanitizer.bypassSecurityTrustUrl(url);
      }),
    );
  }
}
