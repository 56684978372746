<ui-page
  [uiPage]="page"
  [uiPageNotFoundTemplate]="pageNotFoundT"
  [uiShowPageNotFound]="templateService.pageNotFound$ | async"
  [uiTrackBy]="trackBy"
  [uiLayout]="layout"
  (uiLayoutChange)="onLayoutChange($event)"
/>

<ng-template #pageNotFoundT>
  <pr-page-not-found />
</ng-template>
