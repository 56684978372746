import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IPage, IWidget, IWidgetLayout, UiPageModule } from '@base-frontend/ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { TemplateService } from '../../services/template';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';

@UntilDestroy()
@Component({
  selector: 'pr-page',
  templateUrl: './page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiPageModule, PageNotFoundComponent],
})
export class PageComponent implements OnInit {
  page: IPage;
  layout: IWidgetLayout;

  constructor(
    public templateService: TemplateService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
  ) {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(e => this.templateService.onRouteChange(_route.snapshot));
  }

  ngOnInit() {
    this.templateService.page$
      .pipe(
        filter(page => !!page?.PageId),
        untilDestroyed(this),
      )
      .subscribe(page => {
        this.page = page;
        this.layout = loadFromStorage(page.PageId);
        this._cdr.markForCheck();
      });
  }

  onLayoutChange(layout: IWidgetLayout) {
    saveToStorage(this.page.PageId, layout);
  }

  trackBy(index: number, widget: IWidget) {
    return widget.Id;
  }
}

function loadFromStorage(key: string) {
  return JSON.parse(localStorage.getItem(key)) || null;
}

function saveToStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}
