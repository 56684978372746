import { IOidcSettings } from '@base-frontend/core';

const clientRoot = location.origin;

const tenantId = 'b7be7686-6f97-4db7-9081-a23cf09a96b5';
const authority = `https://login.microsoftonline.com/${tenantId}`;

const oidcSettings: IOidcSettings = {
  authority,
  client_id: '98873176-ac6b-4720-a95a-ab27991761d9',
  redirect_uri: `${clientRoot}/signin-callback.html`,
  silent_redirect_uri: `${clientRoot}/silent-callback.html`,
  post_logout_redirect_uri: `${clientRoot}/`,
  response_type: 'code',
  scope: 'api://985389c7-3ced-46d6-ba97-427f2da6a1b9/performanceReport.Data',
  metadata: {
    authorization_endpoint: `${authority}/oauth2/v2.0/authorize`,
    token_endpoint: `${authority}/oauth2/v2.0/token`,
    end_session_endpoint: `${authority}/oauth2/v2.0/logout`,
  },
};

export const environment = {
  production: false,
  useLiteReportsModal: false,
  configFilePath: 'assets/config.cloud.json',
  oidcSettings,
  importEnabled: false,
  exportEnabled: false,
};
