import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CommandQueryService,
  ConfigService,
  MessageHubService,
  NotificationService,
  downloadFile,
  isNil,
} from '@base-frontend/core';
import { BehaviorSubject, asyncScheduler, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, throttleTime } from 'rxjs/operators';
import { TemplateService } from '../template';
import { EReportState, IReport, IReportStatusResponse } from './symbols';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  readonly server = 'Performance';
  readonly reportsThrottleTime = 100;

  templateId$ = this._templateService.templateId$;

  private _reports$ = new BehaviorSubject<IReport[]>(null);
  reports$ = this._reports$.pipe(
    throttleTime(this.reportsThrottleTime, asyncScheduler, { leading: true, trailing: true }),
  );

  private _loadingReports$ = new BehaviorSubject(true);
  loadingReports$ = this._loadingReports$.pipe(distinctUntilChanged());

  private _modalOpen$ = new BehaviorSubject<boolean>(false);
  modalOpen$ = this._modalOpen$.pipe(distinctUntilChanged());

  report$ = combineLatest([this.templateId$, this.reports$]).pipe(
    map(([templateId, reports]) => reports?.find(report => report.TemplateId === templateId)),
  );

  reportBeingExported$ = this.report$.pipe(
    map(report => (report?.State === EReportState.Exporting ? report : null)),
  );

  private _importUploadProgress$ = new BehaviorSubject<number>(null);
  importUploadProgress$ = this._importUploadProgress$.pipe(distinctUntilChanged());
  importProcessProgress$ = this.reports$.pipe(
    map(reports => reports?.find(r => r.State === EReportState.Importing)),
    map(r => (r ? r.Progress : null)),
    distinctUntilChanged(),
    shareReplay(1),
  );
  importingReport$ = combineLatest([this.importUploadProgress$, this.importProcessProgress$]).pipe(
    map(([uploadProgress, processProgress]) => !isNil(uploadProgress) || !isNil(processProgress)),
    distinctUntilChanged(),
  );

  get templateId() {
    return this._templateService.templateId;
  }

  get serverConfig() {
    return this._configService.config.Servers[this.server];
  }

  get reports() {
    return this._reports$.getValue();
  }

  constructor(
    private _configService: ConfigService,
    private _router: Router,
    private _templateService: TemplateService,
    private _commandQueryService: CommandQueryService,
    private _messageHubService: MessageHubService,
    private _notificationService: NotificationService,
    private _http: HttpClient,
  ) {
    this.subscribeToReportStatusUpdates();
    this.loadReports();
  }

  openModal() {
    this._modalOpen$.next(true);
  }

  closeModal() {
    this._modalOpen$.next(false);
  }

  loadReport(templateId: string) {
    this._templateService.navigate({ TemplateId: templateId });
  }

  deleteReport(templateId: string) {
    this._commandQueryService
      .sendCommand(this.server, {
        Key: 'Delete',
        TemplateId: templateId,
      })
      .toPromise();
  }

  exportReport(templateId: string, comment: string, exportToCloud: boolean = false) {
    this._commandQueryService
      .sendCommand(this.server, {
        Key: 'Export',
        TemplateId: templateId,
        Comment: comment,
        ExportToCloud: exportToCloud,
      })
      .toPromise();
  }

  importReport(file: Blob) {
    const form = new FormData();
    form.append('File', file);
    form.append('ClientId', this._configService.config.ClientId);
    form.append('DeviceId', this._configService.config.DeviceId);

    this._http
      .post(`${this.serverConfig.Api}/Import`, form, { reportProgress: true, observe: 'events' })
      .subscribe(
        event => {
          switch (event.type) {
            case HttpEventType.Sent:
              this._importUploadProgress$.next(0);
              break;
            case HttpEventType.UploadProgress:
              this._importUploadProgress$.next(Math.floor((event.loaded / event.total) * 100));
          }
        },
        response => {
          const message =
            typeof response.error === 'string' ? response.error : 'Failed to import report';
          this._notificationService.error(message, '');
          this._importUploadProgress$.next(null);
        },
      );
  }

  loadReports() {
    this._loadingReports$.next(true);

    this._commandQueryService
      .sendQuery<IReportStatusResponse[]>(this.server, {
        Key: 'Status',
        HoursAgo: 0,
        CommandKey: 'PerformanceReport',
      })
      .subscribe(
        statuses => {
          const reports = statuses
            .filter(s => !this.excludeFromReportList(s))
            .map(s => this.transformStatusToReport(s));
          this.sort(reports);
          this._reports$.next(reports);

          setTimeout(() => this._loadingReports$.next(false), this.reportsThrottleTime);
        },
        error => this._loadingReports$.next(false),
      );
  }

  saveComment(templateId: string, comment: string) {
    this._reports$.next([...this.reports]);
    this._commandQueryService
      .sendQuery<IReportStatusResponse[]>(this.server, {
        Key: 'Comment',
        TemplateId: templateId,
        Comment: comment,
      })
      .subscribe(
        () => {},
        () => {
          this._notificationService.error('Failed to save comment', '');
        },
      );
  }

  private subscribeToReportStatusUpdates() {
    this._messageHubService.received$
      .pipe(
        filter(m => m.Key === 'Status' && m.CommandKey === 'PerformanceReport' && !!this.reports),
      )
      .subscribe((s: IReportStatusResponse) => {
        const iInvokedTheCommand = this._configService.config.ClientId === s.ClientId;

        if (s.Status.State === EReportState.Deleted) {
          return this.removeReport(s.Status.TemplateId);
        }

        if (s.Status.State === EReportState.Exported && iInvokedTheCommand) {
          this.downloadExport(s.Status.TemplateId);
        }

        if (s.Status.State === EReportState.ExportError && iInvokedTheCommand) {
          this._notificationService.error('Failed to export report', '');
        }

        if (s.Status.State === EReportState.Importing) {
          this._importUploadProgress$.next(100);
        }

        if (s.Status.State === EReportState.ImportError && iInvokedTheCommand) {
          this._notificationService.error('Failed to import report', '');
        }

        if (s.Status.State === EReportState.Imported) {
          this._importUploadProgress$.next(null);

          if (iInvokedTheCommand) {
            this._templateService.navigate({ TemplateId: s.Status.TemplateId });
            this._modalOpen$.next(false);
          }
        }

        this.updateReportViaStatus(s);
      });
  }

  private excludeFromReportList(response: IReportStatusResponse) {
    return response.Completed && !response.Status.TemplateId;
  }

  private sort(reports: IReport[]) {
    if (!reports) return;

    reports.sort((a, b) => {
      return +new Date(b.DateCreated) - +new Date(a.DateCreated);
    });
  }

  private updateReportViaStatus(status: IReportStatusResponse) {
    const reports = [...this.reports];
    const index = reports.findIndex(r => r.TransactionId === status.TransactionId);

    if (index === -1) {
      reports.push(this.transformStatusToReport(status));
      this.sort(reports);
      this._reports$.next(reports);
    } else {
      reports[index] = this.transformStatusToReport(status);
      this._reports$.next(reports);
    }
  }

  private removeReport(templateId: string) {
    const reports = this.reports.filter(r => r.TemplateId !== templateId);

    if (reports.length !== this.reports.length) {
      if (this._templateService.templateId === templateId) {
        this._router.navigate(['']);
      }
      this._reports$.next(reports);
    }
  }

  private downloadExport(templateId: string) {
    downloadFile(this.serverConfig.File + `/Reports/Report-${templateId}.zip`);
  }

  private transformStatusToReport(status: IReportStatusResponse): IReport {
    return {
      ReportName: status.WellName + ' ' + status.RunNumber,
      DateCreated: status.LastModified,
      TemplateId: status.Status.TemplateId,
      TransactionId: status.TransactionId,
      Completed: status.Completed,
      Progress: status.Status.Progress,
      State: status.Status.State,
      WellName: status.WellName,
      RunNumber: status.RunNumber,
      Comment: status.Comment,
    };
  }
}
