import { PlatformLocation } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { WINDOW } from '../../core.symbols';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IframeUrlPersistenceService {
  private _STORAGE_KEY = `[IFRAME_URL] ${this._platformLocation.getBaseHrefFromDOM()}`;

  private get _isIframe() {
    return this._window.self !== this._window.top;
  }

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _platformLocation: PlatformLocation,
    private _router: Router,
  ) {
    if (!this._isIframe) return;

    this.loadUrlFromStorage();
    this.persistUrls();
  }

  private persistUrls() {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe((event: NavigationEnd) => {
        this.saveUrlToStorage(event.urlAfterRedirects);
      });
  }

  private saveUrlToStorage(url: string) {
    this._window.sessionStorage.setItem(this._STORAGE_KEY, url);
  }

  private loadUrlFromStorage() {
    const url = this._window.sessionStorage.getItem(this._STORAGE_KEY);

    if (url && this._router.url !== url) {
      this._router.navigateByUrl(url);
    }
  }
}
