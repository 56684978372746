import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import merge from 'lodash/merge';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { MessageHubService } from '../message-hub/message-hub.service';
import { ICommand, IQuery, IStatusResponse } from './symbols';

@Injectable({
  providedIn: 'root',
})
export class CommandQueryService {
  private _servers = this._configService.config.Servers;

  constructor(
    protected _http: HttpClient,
    private _configService: ConfigService,
    private _messageHub: MessageHubService,
  ) {}

  sendCommand<T>(server: string, command: ICommand) {
    return this._http.post<IStatusResponse<T>>(
      `${this._servers[server].Api}/Command`,
      this.addIdsToPayload(command),
    );
  }

  sendAndListen<T>(server: string, command: ICommand) {
    return this.sendCommand<T>(server, command).pipe(
      switchMap(response => {
        return this._messageHub.filterByTransactionId(response.TransactionId) as Observable<
          IStatusResponse<T>
        >;
      }),
    );
  }

  sendQuery<T>(server: string, query: IQuery) {
    return this._http.post<T>(`${this._servers[server].Api}/Query`, query);
  }

  sendFetch<T>(server: string, query: IQuery) {
    return this._http.post<T>(`${this._servers[server].Api}/Fetch`, query);
  }

  protected addIdsToPayload(payload: any) {
    return merge({}, payload, {
      DeviceId: this._configService.config.DeviceId,
      ClientId: this._configService.config.ClientId,
    });
  }
}
