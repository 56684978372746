// Rxjs wrapper around MutationObserver
import { Observable } from 'rxjs';

export function fromMutation(
  target: Element,
  config?: MutationObserverInit,
): Observable<MutationRecord[]> {
  return new Observable(observer => {
    const mutation = new MutationObserver(mutations => {
      observer.next(mutations);
    });

    mutation.observe(target, config);

    return () => mutation.disconnect();
  });
}
