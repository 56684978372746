// Create an observable which will emit every time the passed in elements get resized
import { Observable } from 'rxjs';

// @ts-ignore
export function fromResize(...elements: Element[]): Observable<ResizeObserverEntry> {
  return new Observable(observer => {
    // @ts-ignore
    const resizeObserver: ResizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        observer.next(entry);
      }
    });

    for (const el of elements) {
      resizeObserver.observe(el);
    }

    return () => resizeObserver.disconnect();
  });
}
