import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CONFIG_FILE_PATH, CoreModule, NullModule, OidcModule } from '@base-frontend/core';
import { UI_SECURE_IMAGE_PIPE_ENABLED, UI_WIDGET_REGISTRY, UiModule } from '@base-frontend/ui';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageComponent } from './components/page/page.component';
import { registerWidgets } from './widgets';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // OidcModule code will not be loaded in prod unless oidcSettings is available
    (environment.oidcSettings ? OidcModule : NullModule).forRoot(environment.oidcSettings),
    CoreModule.forRoot(),
    UiModule.forRoot(),
    RouterModule.forRoot(
      [
        { path: '', component: BlankPageComponent },
        { path: ':TemplateId/:PageId', component: PageComponent },
        { path: ':templateId', redirectTo: ':templateId/', pathMatch: 'full' },
        { path: '**', component: PageNotFoundComponent },
      ],
      { initialNavigation: 'enabledBlocking' },
    ),
  ],
  providers: [
    { provide: UI_WIDGET_REGISTRY, useFactory: registerWidgets },
    { provide: CONFIG_FILE_PATH, useValue: environment.configFilePath },
    { provide: UI_SECURE_IMAGE_PIPE_ENABLED, useValue: !!environment.oidcSettings },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
