import { digitsBeforeDecimal } from './number';

export function cssVar(variable: string) {
  const value = getComputedStyle(document.body).getPropertyValue(variable);
  return value ? value.trim() : undefined;
}

export function downloadFile(url: string, fileName?: string) {
  const a = document.createElement('a');

  if (fileName) {
    a.download = fileName;
  }

  a.rel = 'noopener';
  a.href = url;
  a.dispatchEvent(new MouseEvent('click'));
  URL.revokeObjectURL(a.href);
}

export function saveObjectToFile(fileName: string, object: Object) {
  const bytes = new TextEncoder().encode(JSON.stringify(object));
  const blob = new Blob([bytes], { type: 'application/json;charset=utf-8' });
  downloadFile(URL.createObjectURL(blob), fileName);
}

export function fileToJSON(file: File) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = function () {
      try {
        const json = JSON.parse(this.result as 'string');
        resolve(json);
      } catch (error) {
        reject(error);
      }
    };
    fileReader.onerror = error => reject(error);
    fileReader.readAsText(file);
  });
}

export function formatBytes(
  value: number,
  wholeNumberDigits = 3,
  fractionDigits = 2,
  units = ['B', 'KB', 'MB', 'GB', 'TB'],
  divisor = 1000,
): string {
  let counter = 0;
  let convertedValue = value;

  if (!value) {
    return '';
  }

  while (digitsBeforeDecimal(convertedValue) > wholeNumberDigits) {
    convertedValue = convertedValue / divisor;
    counter++;
  }

  return +convertedValue.toFixed(fractionDigits) + (units.length ? ` ${units[counter]}` : '');
}

export function copyToClipboard(str = '') {
  if (typeof document !== 'undefined') {
    const prevFocusedElement = <HTMLElement>document.activeElement;
    const selectionBox = <HTMLTextAreaElement>document.createElement('textarea');
    selectionBox.style.position = 'fixed';
    selectionBox.style.left = '0';
    selectionBox.style.top = '0';
    selectionBox.style.opacity = '0';
    selectionBox.value = str;
    document.body.appendChild(selectionBox);
    selectionBox.focus();
    selectionBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectionBox);
    prevFocusedElement.focus();
  }
}
