import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ui-widget-body',
  template: '<ng-content></ng-content>',
  styleUrls: ['./widget-body.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-widget-body',
  },
})
export class UiWidgetBodyComponent {}
