import {
  animate,
  AnimationBuilder,
  AnimationMetadata,
  AnimationPlayer,
  style,
} from '@angular/animations';
import { DomPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { WINDOW } from '@base-frontend/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnimationCurves, AnimationDuration } from 'ng-zorro-antd/core/animation';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, mapTo, skip, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[uiMaximize]',
  exportAs: 'uiMaximize',
})
export class UiMaximizeDirective implements OnDestroy, OnInit {
  @Input()
  set uiMaximized(maximized: boolean) {
    this._isMaximized$.next(maximized);
  }

  private _portal = new DomPortal(this._el);
  private _portalOutlet = new DomPortalOutlet(
    this._document.body,
    this._componentFactoryResolver,
    this._appRef,
    this._injector,
    this._document,
  );
  private _isMaximized$ = new BehaviorSubject(false);
  private _player: AnimationPlayer;

  private get _el() {
    return this._elRef.nativeElement;
  }

  get isMaximized() {
    return this._isMaximized$.getValue();
  }

  constructor(
    private _appRef: ApplicationRef,
    private _elRef: ElementRef<HTMLElement>,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _renderer: Renderer2,
    private _animationBuilder: AnimationBuilder,
    private _cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(WINDOW) private _window: Window,
  ) {}

  ngOnInit() {
    const escapePressed$ = fromEvent(this._window, 'keydown').pipe(
      filter(({ key }: KeyboardEvent) => key === 'Escape'),
    );

    this._isMaximized$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        switchMap(isMaximized => (isMaximized ? this.maximize$() : this.minimize$())),
        filter(isMaximized => isMaximized),
        switchMap(() => escapePressed$),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._isMaximized$.next(false);
      });
  }

  ngOnDestroy() {
    if (this._portal.isAttached) {
      this._portal.detach();
    }
    this._portal = null;
    this._portalOutlet = null;
    this.destroyPlayer();
  }

  toggleMaximize() {
    this._isMaximized$.next(!this.isMaximized);
  }

  private minimize$() {
    // Find destination size/position to animate to
    this.detachPortal();
    this.destroyPlayer();
    const rect = this._el.getBoundingClientRect();

    // Re-attach so that we can animate it
    this._portal.attach(this._portalOutlet);
    return this.runAnimation(createMinimizeAnimation(rect)).pipe(
      // Detach when animation is complete
      tap(() => {
        this.detachPortal();
        this.destroyPlayer();
      }),
      mapTo(false),
    );
  }

  private maximize$() {
    const rect = this._el.getBoundingClientRect();
    this.attachPortal();
    return this.runAnimation(createMaximizeAnimation(rect)).pipe(mapTo(true));
  }

  private runAnimation(animation: AnimationMetadata | AnimationMetadata[]) {
    this.destroyPlayer();

    return new Observable(subscriber => {
      const animationFactory = this._animationBuilder.build(animation);
      this._player = animationFactory.create(this._el);
      this._player.onDone(() => {
        subscriber.next();
        subscriber.complete();
      });
      this._player.play();
    });
  }

  private detachPortal() {
    if (this._portal.isAttached) {
      this._portal.detach();
    }
  }

  private attachPortal() {
    if (!this._portal.isAttached) {
      this._portal.attach(this._portalOutlet);
    }
  }

  private destroyPlayer() {
    this._player?.destroy();
    this._player = null;
  }
}

function createMaximizeAnimation({ top, left, width, height }): AnimationMetadata[] {
  return [
    style({
      position: 'fixed',
      top,
      left,
      width,
      height,
      zIndex: 5,
    }),
    animate(
      `${AnimationDuration.SLOW} ${AnimationCurves.EASE_OUT_QUINT}`,
      style({
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }),
    ),
  ];
}

function createMinimizeAnimation({ top, left, width, height }): AnimationMetadata[] {
  return [
    style({
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 5,
    }),
    animate(
      `${AnimationDuration.SLOW} ${AnimationCurves.EASE_OUT_QUINT}`,
      style({
        top,
        left,
        width,
        height,
      }),
    ),
  ];
}
