import {
  Directive,
  ElementRef,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[uiTruncateText]',
})
export class UiTruncateTextDirective implements OnInit, OnDestroy {
  @HostBinding('class.u-text-truncate') textTruncate = true;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2, private _ngZone: NgZone) {}

  ngOnInit() {
    const element = this._elRef.nativeElement;

    let prevTitle: string;
    this._ngZone.runOutsideAngular(() => {
      fromEvent(element, 'mouseenter')
        .pipe(
          filter(() => this.isTextTruncated()),
          tap(() => {
            prevTitle = element.title;
            this._renderer.setAttribute(
              element,
              'title',
              element.textContent + '\n' + element.title,
            );
          }),
          switchMap(() => fromEvent(element, 'mouseleave')),
          tap(() => this._renderer.setAttribute(element, 'title', prevTitle)),
          untilDestroyed(this),
        )
        .subscribe(() => {});
    });
  }

  ngOnDestroy() {}

  private isTextTruncated() {
    const element = <HTMLElement>this._elRef.nativeElement;
    return element.offsetWidth < element.scrollWidth;
  }
}
